import { Link } from "gatsby";
import queryString from "query-string";
import * as React from "react";
import { connect } from "react-redux";
import Button from "src/components/Button/Button";
import Typography from "src/components/Typography";
import successMackot from "src/images/successMaskot.svg";
import FullWithLayout from "src/routes/FullWithLayout";
import { PageProps, User } from "src/types";
import styles from "./SuccessPayment.module.css";

interface ConnectStateProps {
  user?: User;
}

interface Props extends ConnectStateProps, PageProps {}

interface State {
  type: string;
}

class SuccessPayment extends React.Component<Props, State> {
  public texts = {
    play_list: "Можешь наслаждаться видео.",
    course: "Можешь начать проходить курс.",
    scenario_review:
      "Оплата прошла успешно! Мы получили и уже читаем твой сценарий! Отслеживай свой статус в личном кабинете.",
  };

  public types = {
    play_list: "/medialibrary",
    scenario_review: "/account/reviews",
    course: "/account/courses",
  };

  public async componentDidMount() {
    const query = queryString.parse(this.props.location.search);

    if (!this.props.user && !query.type) {
      await this.props.navigate("/");
    }

    this.setState({ type: String(query.type).toLowerCase() });
  }

  public render() {
    return (
      <FullWithLayout sideBar={<img src={successMackot} />}>
        <div className={styles.textWrapper}>
          <Typography type="h4">Оплата прошла успешно!</Typography>
          <br />
          <Typography type="h4">
            {this.texts[this.state?.type] || null}
          </Typography>
          <div>
            <Link to={this.types[this.state?.type] || "/account/orders"}>
              <Button>СМОТРЕТЬ</Button>
            </Link>
          </div>
        </div>
      </FullWithLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect<ConnectStateProps>(mapStateToProps)(SuccessPayment);
