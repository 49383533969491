import * as React from "react";
import Seo from "src/components/Seo";
import SuccessPaymentRoute from "src/routes/SuccessPayment";
import { PageProps } from "src/types";

const SuccessPayment = (props: PageProps) => {
  return (
    <>
      <Seo pageTitle="Успешная оплата" />
      <SuccessPaymentRoute {...props} />
    </>
  );
};

export default SuccessPayment;
