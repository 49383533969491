import * as React from "react";
import styles from "./FullWithLayout.module.css";

interface Props {
  className?: string;
  sideBar: React.ReactNode;
  white?: boolean;
}

class FullWithLayout extends React.Component<Props> {
  public render() {
    return (
      <main
        className={`${this.props.className} ${styles.fullWidthWrapper} ${this
          .props.white && styles.white}`}
      >
        <div className={styles.sideBar}>{this.props.sideBar}</div>
        <div className={styles.children}>{this.props.children}</div>
      </main>
    );
  }
}

export default FullWithLayout;
